/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useState } from 'react';

/**
 * @file useOutsideClick.ts
 * @module useOutsideClick
 * @author Fahis <mshareef@amgne.com>
 *
 * @description This hook allows you to track clicks outside of a specific DOM element. When a click occurs outside of the element
 * passed through the ref parameter, the callback function is called.
 * @param {React.MutableRefObject<any>|React.MutableRefObject<any>[]} ref - A reference to a DOM element or an array of references to DOM elements.
 * @param {Function} callback - A callback function that is called when a click outside of the ref element occurs.
 * @param {string} [classNames] - A string of space-separated class names of elements to be ignored when clicked.
 * @return {Function} A function to control whether the hook is active or not.
 */
export const useOutsideClick = (ref, callback, classNames) => {
  const [mount, setMount] = useState(true);
  const handleClick = useCallback(
    (e) => {
      if (
        classNames &&
        e.target instanceof HTMLDivElement &&
        e.target.classList.contains(classNames)
      ) {
        return;
      }
      if (
        e.target instanceof HTMLInputElement &&
        e.target.type === 'checkbox'
      ) {
        return;
      }
      if (Array.isArray(ref)) {
        const outsideClick = ref.every(
          (element) => element?.current && !element.current.contains(e.target)
        );
        if (outsideClick) {
          callback();
        }
      } else if (ref?.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [classNames, callback, ref]
  );
  useEffect(() => {
    if (mount) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [mount, handleClick]);

  return (flag) => setMount(flag);
};
