/* eslint-disable */
import * as React from 'react';

function SingleSelectFilter({
  allowSearch,
  options,
  labelField,
  valueField,
  value,
  applyFilter,
  label,
  onClose,
}) {
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState(value || undefined);

  React.useEffect(() => {
    setSelected(value);
  }, [value]);

  const onSelect = (value, sel) => {
    if (sel) {
      setSelected(value);
    } else {
      setSelected(undefined);
    }
  };

  const onApply = () => {
    setSearch('');
    applyFilter?.(selected);
  };

  const onClosed = () => {
    setSearch('');
    onClose?.();
  };

  const filteredOptions = search
    ? options?.filter((op) => op[labelField]?.match(new RegExp(search, 'i')))
    : options;

  return (
    <div className="pb-3">
      <div className="position-sticky top-0 zIndex-99 d-flex align-items-center justify-content-between bg-primary px-3 py-2 text-white">
        <h6 className="m-0 font-xs font-weight-semibold">{label}</h6>
        <span
          className="cursor-pointer d-inline-block text-center rounded-circle sub-dropdown-back ml-3"
          onClick={onClosed}
        >
          <i className="icon-close font-xxs" />
        </span>
      </div>
      {allowSearch && (
        <div className="search">
          <div className="input-group border-bottom">
            <input
              type="text"
              className="form-control border-0 pr-0"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <span className="btn btn-link border-0 bg-transparent px-3 py-0">
                <i
                  className="icon-search position-absolute align-xy-center font-weight-bold text-muted"
                  style={{ fontSize: '1rem' }}
                />
              </span>
            </div>
          </div>
        </div>
      )}
      <ul className="list-unstyled single-select p-2 m-0">
        {filteredOptions?.map((option, index) => {
          const label = option[labelField];
          const value = option[valueField];

          return (
            <li key={label + value.toString()} className="py-2">
              <input
                type="radio"
                id={value.toString()}
                onChange={(e) => onSelect({ label, value }, e.target.checked)}
                checked={selected?.value === value}
              />
              <label
                htmlFor={value.toString()}
                className="font-xs font-weight-semibold"
              >
                {label}
              </label>
            </li>
          );
        })}
      </ul>
      <div className="px-3 text-right position-sticky bottom-0 zIndex-99 bg-white pt-3 pb-2 ">
        <button
          type="button"
          disabled={selected === undefined}
          className="btn btn-primary py-2 px-4 font-weight-semibold"
          onClick={onApply}
        >
          <span className="px-2">Apply</span>
        </button>
      </div>
    </div>
  );
}

export default SingleSelectFilter;
