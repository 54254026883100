export * from './findNestedObjectValue';
export * from './array-utils';

export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
};

export const AppPermissions = {
  SaveFilter: 'save_filter',
  RemoveFilter: 'remove_filter',
};

/**
 * Escapes special characters in a regular expression pattern.
 *
 * @param {string} string - The input string to escape.
 * @returns {string} The escaped string.
 */
export const escapeRegExp = (string) =>
  string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
