/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useMemo } from 'react';
import DataTable, { defaultThemes } from 'react-data-table-component';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import DataTableExtensions from 'react-data-table-component-extensions';
import { colors } from '@mui/material';
import { unstable_batchedUpdates } from 'react-dom';
import * as constant from '../Constants/constant';
import 'react-data-table-component-extensions/dist/index.css';
import { makeDateFormat } from '../../helper/helper';
import { customStyles } from './customStyletable';
import Loader from '../loader/loader';
import { ListTable, ListTableColumn } from '../Grids/list-table';
import { ListHeader, ListHeaderSearch } from '../Grids/list-header';
import ListFilters from '../Grids/list-filters/ListFilters';
import SingleSelectFilter from '../Grids/list-filters/SingleSelectFilter';
import MultiSelectFilter from '../Grids/list-filters/MultiSelectFilter';

function DynamicDataTable(props) {
  axios.defaults.withCredentials = true;
  const { allowedApp, role, initialLoader, active } = useSelector(
    (state) => state.dashboard
  );
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [customFilters, setCustomFilters] = useState({
    MailDomain: [],
    City: [],
    Profession: [],
  });
  // const [perPage, setPerPage] = useState(50);
  const perPage = 100;
  const page = 1;
  // const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState('');

  const columns = useMemo(
    () => [
      <ListTableColumn
        label="Email"
        column="email"
        fixed
        fixedPosition="start"
        sortable
        showDefault
        settingsGroup="email"
        formatContent={(rowData) => {
          if (rowData.email?.length > 60) {
            return `${rowData.email.substring(0, 30)}...`;
          }
          return rowData.email;
        }}
        sortKey="email"
      />,
      <ListTableColumn
        label="Application Name"
        column="aliasName"
        source="aliasName"
        sortable
        showDefault
        settingsGroup="aliasName"
        formatContent={(rowData) => {
          return rowData.aliasName;
        }}
        sortKey="aliasName"
      />,
      <ListTableColumn
        label="Other Apps"
        column="secondary_application"
        source="secondary_application"
        sortable
        showDefault
        settingsGroup="secondary_application"
        sortKey="secondary_application"
      />,
      <ListTableColumn
        label="Prospect id"
        column="prospect_id"
        source="prospect_id"
        sortable
        showDefault
        settingsGroup="prospect_id"
        sortKey="prospect_id"
      />,
      <ListTableColumn
        label="Party id"
        column="MDM_ID"
        source="MDM_ID"
        sortable
        showDefault
        settingsGroup="MDM_ID"
        sortKey="MDM_ID"
      />,
      <ListTableColumn
        label="Status"
        column="MDM_Status"
        source="MDM_Status"
        sortable
        showDefault
        settingsGroup="MDM_Status"
        sortKey="MDM_Status"
      />,
      <ListTableColumn
        label="Profession"
        column="Title"
        source="Title"
        sortable
        showDefault
        settingsGroup="Title"
        sortKey="Title"
      />,
      <ListTableColumn
        label="First name"
        column="first_name"
        source="first_name"
        sortable
        showDefault
        settingsGroup="first_name"
        sortKey="first_name"
      />,
      <ListTableColumn
        label="Last name"
        column="last_name"
        source="last_name"
        sortable
        showDefault
        settingsGroup="last_name"
        sortKey="last_name"
      />,
      <ListTableColumn
        label="Phone"
        column="mobile_number"
        source="mobile_number"
        sortable
        showDefault
        settingsGroup="mobile_number"
        sortKey="mobile_number"
      />,
      <ListTableColumn
        label="Postal code"
        column="postal_code"
        source="postal_code"
        sortable
        showDefault
        settingsGroup="postal_code"
        sortKey="postal_code"
      />,
      <ListTableColumn
        label="City"
        column="city"
        source="city"
        sortable
        showDefault
        settingsGroup="city"
        sortKey="city"
      />,
      <ListTableColumn
        label="Institution"
        column="institution"
        source="institution"
        sortable
        showDefault
        settingsGroup="institution"
        sortKey="institution"
      />,
      <ListTableColumn
        label="Specialty"
        column="Specialty"
        source="Specialty"
        sortable
        showDefault
        settingsGroup="Specialty"
        sortKey="Specialty"
      />,
      <ListTableColumn
        label="HCP Validation Submitted"
        column="hcp_validation_submitted_date"
        source="hcp_validation_submitted_date"
        sortable
        showDefault
        settingsGroup="hcp_validation_submitted_date"
        sortKey="hcp_validation_submitted_date"
      />,
      <ListTableColumn
        label="Last login"
        column="last_login_timestamp"
        source="last_login_timestamp"
        sortable
        showDefault
        settingsGroup="last_login_timestamp"
        sortKey="last_login_timestamp"
      />,
      <ListTableColumn
        label="Onekey id"
        column="one_key_id"
        source="one_key_id"
        sortable
        showDefault
        settingsGroup="one_key_id"
        sortKey="one_key_id"
      />,
      <ListTableColumn
        label="Multipass Result"
        column="multipassParams"
        source="multipassParams"
        sortable
        showDefault
        settingsGroup="multipassParams"
        sortKey="multipassParams"
      />,
      <ListTableColumn
        label="License Number"
        column="validationId"
        source="validationId"
        sortable
        showDefault
        settingsGroup="validationId"
        sortKey="validationId"
      />,
      <ListTableColumn
        label="Quick Register"
        column="quickregister"
        source="quickregister"
        sortable
        showDefault
        settingsGroup="quickregister"
        sortKey="quickregister"
      />,
    ],
    []
  );
  const TABLE_ID = 'table-to-xls';

  const fetchData = async (pages, per_page) => {
    if (pages === 1 && isLoading) {
      return;
    }
    setIsLoading(true);
    const payload = {};

    if (props.country) {
      payload.country = props.country;
    }
    if (props.startdate) {
      payload.startDate = props.startdate.toISOString();
    }
    if (props.enddate) {
      payload.endDate = props.enddate.toISOString();
    }
    if (props.dashapp) {
      payload.appName = props.dashapp;
    }
    payload.search = search;
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
          hcpPending_new: true,
          ...payload,
          limit: per_page,
          offset: (pages - 1) * per_page,
        },
        {
          headers: { ...constant.headers, 'csrf-token': props.csrf },
        }
      )
      // .then(res => res.json())
      .then(
        (result) => {
          const prev = customFilters;
          let obj = customFilters;
          if (result.data.result.length > 0) {
            result.data.result.map((item) => {
              if (item.email || item.city || item.Title) {
                const domain = item.email.split('@')[1];
                const { city } = item;
                const Profession = item.Title;
                if (!prev.MailDomain.includes(domain)) {
                  obj = {
                    ...prev,
                    MailDomain: [...prev.MailDomain, domain],
                  };
                }
                if (!prev.City.includes(city)) {
                  obj = {
                    ...prev,
                    City: [...prev.City, city],
                  };
                }
                if (!prev.Profession.includes(Profession)) {
                  obj = {
                    ...prev,
                    Profession: [...prev.Profession, Profession],
                  };
                }
              }
              if (item.secondary_application) {
                const al = item.secondary_application
                  .split(',')
                  .filter(Boolean);
                if (al.length > 0) {
                  const updatedApp = al.map(
                    (app) => constant.appList[app] || app
                  );
                  item.secondary_application = updatedApp.join(', ');
                }
              }
              return item;
            });
          }
          unstable_batchedUpdates(() => {
            setIsLoading(false);
            setCustomFilters(obj);
            setItems((prev) => [...prev, ...result.data.result]);
            setTotalRows(Number(result.data?.totalRows) ?? 0);
          });
        },
        (error) => {
          unstable_batchedUpdates(() => {
            setIsLoading(false);
            setError(error);
          });
        }
      );
  };
  const handlePageChange = (pages) => {
    fetchData(pages, perPage);
  };
  useEffect(() => {
    setItems([]);
    fetchData(page, perPage);
    const tableElement = document.getElementById('idm-table-container');
    if (tableElement) {
      tableElement.scrollTop = 0;
    }
  }, [search, props.country, props.startdate, props.enddate, props.dashapp]);
  useEffect(() => {
    const containerElement = document.getElementById('idm-table-container');
    const handleScroll = () => {
      const tableElement = document.getElementById(TABLE_ID);
      const containerElementa = document.getElementById('idm-table-container');
      const { scrollTop } = containerElementa;
      if (
        !isLoading &&
        Math.ceil(tableElement.offsetHeight - containerElementa.offsetHeight) <=
          Math.ceil(scrollTop)
      ) {
        const count = items?.length ?? 0;
        if (Number(totalRows) > count) {
          handlePageChange(page + 1);
        }
      }
    };
    if (containerElement) {
      containerElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [page, isLoading, totalRows, items, search]);

  const [filters, setFilters] = useState({});

  const filterRow = useMemo(
    () => (
      <ListFilters
        key="filter-row"
        savedFilters={[]}
        onSaveFilter={async (name) => {
          console.log(name);
        }}
        isSavingFilter={false}
        onRemoveFilter={async (Id) => {
          console.log(Id);
        }}
        filters={filters}
        onFilter={() => {
          console.log('filter applied');
        }}
      >
        <SingleSelectFilter
          label="Status"
          filterKey="status"
          key="status"
          options={[
            {
              key: 'approved',
              label: 'Approved',
            },
            {
              key: 'pending',
              label: 'Pending',
            },
            {
              key: 'rejected',
              label: 'Rejected',
            },
          ]}
          valueField="key"
          labelField="label"
        />
        <MultiSelectFilter
          label="Mail Domain"
          filterKey="mailDomain"
          key="mailDomain"
          options={customFilters.MailDomain.map((domain) => ({
            value: domain,
            label: domain,
          }))}
          valueField="value"
          labelField="label"
        />
        <MultiSelectFilter
          label="Profession"
          filterKey="Profession"
          key="Profession"
          options={customFilters.Profession.map((element) => ({
            value: element,
            label: element,
          }))}
          valueField="value"
          labelField="label"
        />
        <MultiSelectFilter
          label="City"
          filterKey="City"
          key="City"
          options={customFilters.City.map((element) => ({
            value: element.trim() ?? 'NA',
            label: element.trim() ?? 'NA',
          }))}
          valueField="value"
          labelField="label"
        />
      </ListFilters>
    ),
    [
      filters,
      customFilters.MailDomain,
      customFilters.Profession,
      customFilters.City,
    ]
  );
  if (role != 'Super Admin' && (!props.country || !props.vijay)) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '56vh',
        }}
      >
        Please select country and date range for data shown here...
      </div>
    );
  }
  return (
    <div className="hcp-pending" style={{ height: 'inherit' }}>
      <ListHeader
        noPadding
        title={`Records ${items.length} of ${totalRows ?? 0}`}
        additionalRows={[
          filterRow,
          <ListHeaderSearch
            placeholder="Search"
            serachTerm={search}
            onSearch={setSearch}
          />,
          <i className="icon-download m-1 mx-2 font-xl" />,
        ]}
      />
      <ListTable
        data={items}
        id={TABLE_ID}
        showSelectOption={false}
        showColumnSettings
        isLoading={isLoading}
        selectedColumns={[
          { key: 'email', order: 1 },
          { key: 'MDM_ID', order: 2 },
          { key: 'aliasName', order: 3 },
          { key: 'secondary_application', order: 4 },
          { key: 'prospect_id', order: 5 },
          { key: 'MDM_Status', order: 6 },
          { key: 'Title', order: 7 },
          { key: 'first_name', order: 8 },
          { key: 'last_name', order: 9 },
          { key: 'mobile_number', order: 10 },
          { key: 'postal_code', order: 11 },
          { key: 'city', order: 12 },
        ]}
        onColumnSettingsSaved={() => {}}
        selectedValues={[]}
        onSelect={() => {}}
      >
        {columns}
      </ListTable>
    </div>
  );
}

export default DynamicDataTable;
