/* eslint-disable */
import * as React from 'react';

function MultiSelectFilter({
  allowSearch,
  anotherSearchKey,
  applyFilter,
  options,
  onClose,
  label,
  labelField,
  valueField,
  value,
  dynamicElement,
}) {
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState(value || []);
  React.useEffect(() => {
    setSelected(value || []);
  }, [value]);

  const onSelect = (value, sel) => {
    if (sel) {
      setSelected([...(selected || []), value]);
    } else {
      setSelected(selected?.filter((val) => val.value !== value.value) || []);
    }
  };

  const onAllSelected = (isSelected) => {
    const allValues = !isSelected
      ? []
      : filteredOptions?.map((ff) => ({
          label: ff[labelField],
          value: ff[valueField],
        }));

    setSelected(allValues);
  };

  const onApply = () => {
    setSearch('');
    applyFilter?.(selected);
  };

  const onClosed = () => {
    setSearch('');
    onClose?.();
  };

  let filteredOptions;

  if (anotherSearchKey) {
    filteredOptions = search
      ? options?.filter(
          (op) =>
            op[anotherSearchKey]?.match(new RegExp(search, 'i')) ||
            op[labelField]?.match(new RegExp(search, 'i')) ||
            op[valueField]?.match(new RegExp(search, 'i'))
        )
      : options;
  } else {
    filteredOptions = search
      ? options?.filter(
          (op) =>
            op[labelField]?.match(new RegExp(search, 'i')) ||
            op[valueField]?.match(new RegExp(search, 'i'))
        )
      : options;
  }

  return (
    <div className="pb-3">
      <div className="position-sticky top-0 zIndex-99 d-flex align-items-center justify-content-between bg-primary px-3 py-2 text-white">
        <h6 className="m-0 font-xs font-weight-semibold">{label}</h6>
        <span
          className="cursor-pointer d-inline-block text-center rounded-circle sub-dropdown-back ml-3"
          onClick={onClosed}
        >
          <i className="icon-close font-xxs" />
        </span>
      </div>
      {allowSearch && (
        <div className="search">
          <div className="input-group border-bottom">
            <input
              type="text"
              className="form-control border-0 pr-0"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <span className="btn btn-link border-0 bg-transparent px-3 py-0">
                <i
                  className="icon-search position-absolute align-xy-center font-weight-bold text-muted"
                  style={{ fontSize: '1rem' }}
                />
              </span>
            </div>
          </div>
        </div>
      )}
      <ul className="list-unstyled p-2 m-0 pl-0 ">
        {!!filteredOptions?.length && (
          <li key="all-0" className="py-2 pl-2 pb-3 border-bottom">
            <input
              type="checkbox"
              id={`all-${label}`}
              onChange={(e) => onAllSelected(e.target.checked)}
              checked={
                !filteredOptions.find(
                  (ff) => !selected.find((fff) => ff[valueField] === fff.value)
                )
              }
            />
            <label
              htmlFor={`all-${label}`}
              className="font-xs font-weight-semibold"
            >
              {`All ${label}`}
            </label>
          </li>
        )}
        {filteredOptions?.map((option, index) => {
          const label = option[labelField];
          const value = option[valueField];
          const id = `${label}-${value}-${index}`;
          return (
            <li key={option[valueField]} className="py-2 pl-2 ">
              <input
                type="checkbox"
                id={id}
                onChange={(e) => onSelect({ label, value }, e.target.checked)}
                checked={!!selected?.find((fv) => fv.value === value)}
              />
              <label htmlFor={id} className="font-xs font-weight-semibold">
                {dynamicElement ? dynamicElement(option) : label}
              </label>
            </li>
          );
        })}
      </ul>
      <div className="px-3 text-right position-sticky bottom-0 zIndex-99 bg-white pt-3 pb-2 ">
        <button
          type="button"
          disabled={selected.length === 0}
          className="btn btn-primary py-2 px-4 font-weight-semibold"
          onClick={onApply}
        >
          <span className="px-2">Apply</span>
        </button>
      </div>
    </div>
  );
}

export default MultiSelectFilter;
