export const getChunks = (arr, size) => {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};

export const getUniqueValues = (arr) => {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
};
