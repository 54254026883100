/* eslint-disable */
import * as React from 'react';

function SavedFilters({ items, onRemove, onSelect }) {
  if (!items?.length) {
    return null;
  }
  return (
    <div className="saved-filter px-3">
      <h6 className="text-uppercase font-xxxs text-gray-500 m-0 pt-3">
        Saved Filter
      </h6>
      <ul className="list-unstyled p-0 m-0 font-weight-semibold">
        {items?.map((item, index) => (
          <li
            key={item.id}
            className="d-flex justify-content-between align-items-center border-bottom py-3 pl-2 list-items"
          >
            <span className="w-100" onClick={() => onSelect?.(item.value)}>
              {item.name}{' '}
            </span>
            {onRemove && (
              <span
                className="icon-bin font-lg cursor-pointer alert-modal mr-2"
                onClick={() => onRemove?.(item.id)}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SavedFilters;
