/* eslint-disable */
import classnames from 'classnames';
import { useOutsideClick } from '../../../hooks';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { escapeRegExp } from '../../../utils';
import AddedFilter from './AddedFilter';
import SaveFilter from './SaveFilter';
import SavedFilters from './SavedFilters';
import 'bootstrap-daterangepicker/daterangepicker.css';

const ListFilters= (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const ref = React.useRef(null);
  const filterRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const addFilterRef = React.useRef(null);
  const [selectedFilter, setSelectedFilter] = React.useState();
  const [filters, setFilters] = React.useState({
    ...props.filters,
  });

  const changeMount = useOutsideClick([dropdownRef, ref], () => {
    setIsOpen(false);
    setSearch('');
    setSelectedFilter(undefined);
  });

  React.useEffect(() => {
    setFilters({ ...props.filters });
  }, [props.filters]);

  const setFilterValue = React.useCallback(
    (key, value) => {
      if (!value) {
        resetFilterValue(key);
      } else {
        const newFilters = {
          ...filters,
          [key]: value,
        };
        setFilters(newFilters);
        setIsOpen(false);
        setSelectedFilter(undefined);
        props.onFilter?.(newFilters);
      }
    },
    [filters, props.onFilter]
  );

  const resetFilterValue = React.useCallback(
    (key) => {
      const newFilters = { ...filters };
      delete newFilters[key];
      setFilters(newFilters);
      setIsOpen(false);
      setSelectedFilter(undefined);
      props.onFilter?.(newFilters);
    },
    [filters, props.onFilter]
  );

  const resetFilters = React.useCallback(
    (val) => {
      const newFilters = { ...val };
      setFilters(newFilters);
      setIsOpen(false);
      setSelectedFilter(undefined);
      props.onFilter?.(newFilters);
    },
    [props.onFilter]
  );

  const allFilters = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return child;
    }
  })?.filter((child) => !!child);
  if (!allFilters?.length) {
    return <></>;
  }

  const childFilters = React.useMemo(
    () =>
      search
        ? allFilters.filter((child) => child.props.label?.match(new RegExp(escapeRegExp(search), 'i')))
        : allFilters,
    [search, allFilters]
  );
  const savedFilters = React.useMemo(
    () =>
      search && props.savedFilters
        ? props.savedFilters.filter((child) => child.name.match(new RegExp(escapeRegExp(search), 'i')))
        : props.savedFilters,
    [search, props.savedFilters]
  );

  const appliedFiltersCount = React.useMemo(
    () =>
      Object.keys(filters).reduce((count, filterKey) => {
        if (!filterKey) {
          return count;
        }
        const filterValue = filters[filterKey];
        if (!filterValue) {
          return count;
        }
        if (Array.isArray(filterValue)) {
          return count + filterValue.length;
        }
        return count + 1;
      }, 0),
    [filters]
  );

  const renderHeader = () => (
    <div className="text-primary font-xs d-flex align-items-center mr-1 py-3">
      <i className="icon-filter font-md" />
    </div>
  );

  const getAddedFilters = React.useMemo(() => {
    const adFilters = [];
    Object.keys(filters).forEach((filterKey) => {
      const matchingFilter = childFilters.find((child) => child.props.filterKey === filterKey);
      if (!filterKey || props.ignoreAddedFilters?.includes(filterKey)) {
        return <React.Fragment key={`filter-ingnore-${filterKey}`} />;
      }
      const filterValue = filters[filterKey];
      if (!filterValue || matchingFilter?.props?.fixed) {
        return <React.Fragment key={`filter-fixed-${filterKey}`} />;
      }
      if (Array.isArray(filterValue)) {
        let filterValues = filterValue
          ?.map((f) => f.label)
          ?.slice(0, 2)
          ?.join(', ');
        if (filterValue.length > 2) {
          filterValues = `${filterValues}, + ${filterValue.length - 2} more`;
        }

        adFilters.push(
          <AddedFilter
            key={`filter-${filterKey}-${filterValues}`}
            name={matchingFilter?.props?.label || 'Filter'}
            valueLabel={filterValues}
            onRemove={() => resetFilterValue(filterKey)}
            onClick={() => {
              setIsOpen(true);
              setSelectedFilter(filterKey);
              // filterRef?.current?.scrollIntoView();
            }}
          />
        );
      } else {
        adFilters.push(
          <AddedFilter
            key={`filter-${filterKey}`}
            name={matchingFilter?.props?.label || 'Filter'}
            valueLabel={filterValue.label}
            onRemove={() => resetFilterValue(filterKey)}
            onClick={() => {
              setIsOpen(true);
              setSelectedFilter(filterKey);
              // filterRef?.current?.scrollIntoView();
            }}
          />
        );
      }
    });

    return adFilters;
  },[filters, resetFilterValue]);

  const renderFixedFilters = () =>
    childFilters.map((child, index) => {
      const childProps = child.props;
      if (!childProps.fixed) {
        return <React.Fragment key={index} />;
      }

      return React.cloneElement(child, {
        ...childProps,
        key: index,
        applyFilter: (value) => setFilterValue(childProps.filterKey, value),
        resetFilter: () => resetFilterValue(childProps.filterKey),
        onClose: () => setSelectedFilter(undefined),
        value: filters[childProps.filterKey],
      });
    });

  const renderSearch = () => (
    <div className="search">
      <div className="input-group border-bottom">
        <input
          type="text"
          className="form-control border-0 pr-0"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="input-group-append">
          <span className="btn btn-link border-0  px-4 py-0">
            <i
              className="icon-search position-absolute align-xy-center font-weight-bold text-muted"
              style={{ fontSize: '1rem' }}
            />
          </span>
        </div>
      </div>
    </div>
  );

  const renderFilterSaveOptions = (flag=true) => {
    if (!appliedFiltersCount) {
      return <></>;
    }
    return (
      <div className={`${flag ? "row gutter-8" : "d-flex"}`}>
        {props.onSaveFilter && (
          <div className={`${flag ? "col-auto" : "bg-white rounded shadow-md px-2"}`}>
            <SaveFilter onSave={props.onSaveFilter} isSaving={props.isSavingFilter} />
          </div>
        )}
        <div className={`${flag ? "col-auto px-0" : "ml-1 bg-white rounded shadow-md px-2"}`}>
          <span
            className="cursor-pointer d-inline-block text-gray-500 text-center text-uppercase font-xxxxs font-weight-semibold py-2"
            onClick={() => resetFilters({})}
          >
            <i className="icon-reset font-base d-block mb-1" />
            Reset
          </span>
        </div>
      </div>
    );
  };

  const addedFilters = getAddedFilters;

  const hasFixedFilters =
    childFilters.filter((child, index) => {
      const childProps = child.props;
      return childProps.fixed && filters[childProps.filterKey];
    }).length > 0;

  const hasAddedFilters = addedFilters.length > 0;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      const dropdown = dropdownRef.current;
      if (dropdown && isOpen) {
        if (dropdown.getBoundingClientRect().right > document.documentElement.clientWidth) {
          dropdown.style.transform = `translateX(-${
            dropdown.getBoundingClientRect().right - document.documentElement.clientWidth
          }px)`;
        }
      } else if (!isOpen) {
        if (dropdown?.style) {
          dropdown.style.transform = 'none';
        }
      }
    }, 40);
    if (!isOpen) {
      changeMount(false);
    } else {
      changeMount(true);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  const menuStyles = React.useMemo(
    () => ({
      position: 'absolute',
      top: `${addFilterRef?.current?.getBoundingClientRect()?.bottom}px`,
      left: `${addFilterRef?.current?.getBoundingClientRect()?.left}px`,
      maxWidth: '300px',
    }),
    [addFilterRef, isOpen]
  );
  return (
    <div
      className={classnames({
        'px-lg-4 px-2 filter-blk w-100': props.showFilter !== false,
        // 'filter-selected': (hasFixedFilters || hasAddedFilters) && props.showFilter !== false,
      })}    >
      <div className="d-flex align-items-center">
        <div className="col px-1">
          <div className="d-flex align-items-start">
            {renderHeader()}
            <div key="add-filter" className="col-auto filter-add px-1">
                <div
                  className={classnames({
                    'dropdown dropdown-add-filter': true,
                    in: isOpen,
                  })}
                >
                  <span
                    className="dropdown-click cursor-pointer text-uppercase font-xs font-weight-semibold py-3 d-inline-block"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      if (isOpen) {
                        setSearch('');
                        setSelectedFilter(undefined);
                      }
                    }}
                    ref={addFilterRef}
                  >
                    Add Filter
                  </span>
                  {isOpen &&
                    ReactDOM.createPortal(
                      React.cloneElement(
                        <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                          <div
                            ref={dropdownRef}
                            className={classnames({
                              'dropdown-menu dropdown-shadow font-xs border-0 w-100 p-0 ': true,
                              in: isOpen,
                            })}
                            style={{ maxHeight: '350px', overflowY: 'auto' }}
                          >
                            <div className="position-relative">
                              {selectedFilter === undefined && !props.hideSearch && renderSearch()}
                              {selectedFilter === undefined && (
                                <SavedFilters
                                  items={savedFilters}
                                  onRemove={props.onRemoveFilter}
                                  onSelect={resetFilters}
                                />
                              )}
                              <ul
                                className={classnames({
                                  'list-unstyled  m-0 font-weight-semibold': true,
                                  // 'px-3': selectedFilter === undefined,
                                })}
                                ref={filterRef}
                              >
                                {childFilters.map((child, index) => {
                                  const childProps = child.props;
                                  if (selectedFilter && selectedFilter !== childProps.filterKey) {
                                    return <React.Fragment key={childProps.filterKey} />;
                                  }
                                  if (childProps.fixed) {
                                    return <React.Fragment key={childProps.filterKey} />;
                                  }

                                  if (childProps.noContent) {
                                    return React.cloneElement(child, {
                                      ...childProps,
                                      key: index,
                                      applyFilter: (value) =>
                                        setFilterValue(childProps.filterKey, value),
                                      resetFilter: () => resetFilterValue(childProps.filterKey),
                                      onClose: () => setSelectedFilter(undefined),
                                      value: filters[childProps.filterKey],
                                    });
                                  }

                                  return (
                                    <li
                                      key={child.key}
                                      className={classnames({
                                        'border-bottom list-items': true,
                                        'px-3': selectedFilter === undefined,
                                      })}
                                    >
                                      <div
                                        className={classnames({
                                          'py-3': true,
                                          'd-block': selectedFilter === undefined,
                                          'd-none': selectedFilter !== undefined,
                                        })}
                                        onClick={() => {
                                          setSelectedFilter(
                                            selectedFilter === childProps.filterKey ? undefined : childProps.filterKey
                                          );
                                        }}
                                      >
                                        {childProps.label}
                                      </div>
                                      <div
                                        className={classnames({
                                          'sub-dropdown top-0 left-0 w-100 bg-white zIndex-99': true,
                                          in: selectedFilter === childProps.filterKey,
                                        })}
                                      >
                                        {React.cloneElement(child, {
                                          ...childProps,
                                          applyFilter: (value) =>
                                            setFilterValue(childProps.filterKey, value),
                                          resetFilter: () => resetFilterValue(childProps.filterKey),
                                          onClose: () => setSelectedFilter(undefined),
                                          value: filters[childProps.filterKey],
                                        })}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>,
                        {
                          style: { ...menuStyles },
                        }
                      ),
                      document.body
                    )}
                </div>
              </div>
            <ul className="filter-choices row gutter-5 align-items-center list-unstyled p-0 mb-0">
              {renderFixedFilters()}
              {props.showFilter !== false
                ? addedFilters ? <div className='d-flex align-items-center w-100 py-1'>
                <ul
                  className="filter-choices gutter-5 d-flex list-unstyled p-0 mb-0 ml-1"
                  ref={ref}
                  style={{
                    width: '100%',
                    overflowX: 'auto',
                  }}
                >
                {addedFilters}
                </ul>
                </div> : addedFilters
                : ReactDOM.createPortal(
                  <div className='d-flex align-items-center w-100 py-1 rounded' style={{background: "rgba(0,0,0,.5)"}}>
                    <ul
                      className="filter-choices w-100 d-flex gutter-5 align-items-center list-unstyled p-0 mb-0 ml-1"
                      ref={ref}
                      style={{
                        left: `${addFilterRef?.current?.getBoundingClientRect()?.left + 75}px`,
                        top: `${addFilterRef?.current?.getBoundingClientRect()?.top}px`,
                        width: `100%`,
                        overflowX: 'auto',
                      }}
                    >
                      {addedFilters}
                    </ul>
                    <div className="px-2">{renderFilterSaveOptions(false)}</div>
                  </div>,
                    document.getElementById('map-screen-filters') ?? document.body
                  )}
            </ul>
          </div>
        </div>
        {props.showFilter !== false && <div className="col-auto px-0">{renderFilterSaveOptions()}</div>}
      </div>
    </div>
  );
};

ListFilters.defaultProps = {
  showFilter: true,
};
export default ListFilters;

