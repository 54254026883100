/* eslint-disable */
import * as React from 'react';

function ListHeader(props) {
  return (
    <div className="list-head">
      <div
        className={`${props.noPadding ? '' : 'py-2 px-3'} ${
          props.noBorder ? '' : 'border-bottom'
        }`}
      >
        <div className="row align-items-center pb-2">
          <div className="col-lg-5 col-md-5 col-12">{props.children}</div>
          {props.actions != null && props.actions?.length > 0 && (
            <div className="col-lg-7 col-md-7 col-12">
              <div className="row gutter-12 align-items-center justify-content-end">
                {props.actions?.map((action, index) => {
                  return <div className="col-auto ">{action}</div>;
                })}
              </div>
            </div>
          )}
        </div>
        {/* {props.title && (
          <div className="row">
            <p className='fs-6 mb-0 pb-0' style={{fontSize: "10px"}}>{props.title}</p>
          </div>
        )} */}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {Array.isArray(props.additionalRows) ? 
          (<><div className="col-9 pr-0 d-flex align-items-center">{<><div className="d-flex align-items-center">
            <div className="d-flex flex-column cursor-pointer">
              <p className="m-0 font-sm">{props.title}</p>
            </div>
          </div>{props.additionalRows[0]}</>}</div>
          <div className="col-3 pl-0 d-flex align-items-center justify-content-end">{props.additionalRows.slice(1)}</div></>)
        : props.additionalRows}
      </div>
    </div>
  );
}

export default ListHeader;
