/* eslint-disable */
import * as React from 'react';

function AddedFilter({ onClick, onRemove, valueLabel, name }) {
  return (
    <li className="col-auto filter-choice py-1 px-0">
      <div className="font-xxs bg-primary rounded-lg text-white text-nowrap font-weight-semibold d-flex align-items-center p-1 py-2">
        <span className="filter-hd px-1">{name}: </span>
        <span className="cursor-pointer" onClick={onClick}>
          {valueLabel.length > 25
            ? `${valueLabel.slice(0, 25)}...`
            : valueLabel}
        </span>
        <span
          className="filter-choice-delete icon-close-round font-base pl-2 cursor-pointer"
          onClick={onRemove}
        />
      </div>
    </li>
  );
}

export default React.memo(AddedFilter);
