/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import DateTimePicker from 'react-datetime-picker';
import { Swiper, SwiperSlide } from 'swiper/react';
import AmgenLogo from '../Assets/Group1.svg';
import Sidebar from '../sidebar/Sidebar';
import MyComponent from '../Table/Table';
import DynamicDataTableHcpPending from '../Table/DynamicDataTableHcpPending';
import DynamicDataTableHcpValidated from '../Table/DynamicDataTableHcpValidated';
import UserPopover from '../userpopover';
import * as constant from '../Constants/constant';
import UserTable from './UserTable';
// import {
//   updateLoader,
//   setOnboardData,
//   completeButtonDisable,
//   updateOnboardKind,
//   updateRequestId,
//   setAdminApplication,
//   updateRole,
// } from "../../store/slice";
import { setShow } from '../../store/appslice';
import '../style.css';
import Country from './Countries';
import Application from './Application';
// import Active from "./Active";
import Login from './Login';
// import Newregistration from "./Newregistration";
// import { Hcpvalidateddata } from "./HcpValidateddata";
// import { Hcppendingdata } from "./Hcppendingdata";
import Loader from './Loader';
import Rolenotfound from '../Rolenotfound';
import Loadspinner from '../Assets/Ripple_spinner1.svg';
import {
  setAllowedApplication,
  updateInitialLoader,
  setActive,
  setGetUser,
  setAllowedCountries,
  setSuperAdminAllowedCountries,
  setInitialCountryValues,
  newCountryList,
  newAppList,
} from '../../store/dashboardslice';
import {
  hcpPending,
  hcpValidated,
  activeRegistered,
  applicationsOnboarded,
  login,
  countriesOnboarded,
  newRegistered,
} from '../../helper/helper';
import { setToken } from '../../store/appslice';
// import httpHelper from "../../helper/httpHelper";
// Import Swiper React components

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination, History } from 'swiper/modules';
import { sanitizeUserInput } from '../../helper/sanitize';
import './index.css';

function Dashboard() {
  const [data, setData] = useState('');
  const { info } = useSelector((state) => state.info);
  const navigate = useNavigate();
  const [alldata, setAlldata] = useState('');
  const [filterdata, setFilterdata] = useState('');
  const [hcpvalidated, setHcpvalidated] = useState('');
  const [hcppending, setHcppending] = useState('');
  const dispatch = useDispatch();
  // const [active, setActive] = useState("");
  const user = localStorage.getItem('username');
  const [countrycount, setCountrycount] = useState('0');
  const [applicationcount, setApplicationcount] = useState('0');
  const [hcppendingcount, setHcppendingcount] = useState('0');
  const [hcpvalidatedcount, setHcpvalidatedcount] = useState('0');
  const [userTableCount, setUserTableCount] = useState('0');
  const [loggedincount, setLoggedincount] = useState('0');
  const [activeApplicationData, setActiveApplicationData] = useState([]);
  const { csrf, show } = useSelector((state) => state.app);
  const [goValidated, setGoValidated] = useState(false);
  const [goPending, setGoPending] = useState(false);
  const [goflag, setGoflag] = useState(false);
  // const [activeCircleCount, setActiveCircleCount] = useState(0);
  // const [inactiveCircleCount, setInactiveCircleCount] = useState(0);
  // const [activeregisteredcount, setActiveregisteredcount] = useState(0);
  // const [activeregisteredcount2, setActiveregisteredcount2] = useState(0);
  // const [activeRegisteredData, setActiveRegisteredData] = useState([]);
  const [activeCountryData, setActiveCountryData] = useState([]);
  const [activeLoggedInData, setActiveLoggedInData] = useState([]);
  // const [newregisteredcount, setNewregisteredcount] = useState(0);
  // const [activeNewregisteredData, setActiveNewregisteredData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [dashapp, setDashApp] = useState('');
  const myDate = new Date();
  const [startdate, onChange] = useState(
    new Date(myDate.getTime() - 60 * 60 * 24 * 7 * 1000)
  );
  const [enddate, onChange1] = useState(new Date());
  const [countryval, setCountry] = useState('');
  const [vijay, setVijay] = useState(false);
  const [maxLengthValidated, setMaxLengthValidated] = useState(false);
  const [maxLengthPending, setMaxLengthPending] = useState(false);
  const [cardspin, setCardspin] = useState(false);
  const {
    allowedApp,
    appList,
    role,
    initialLoader,
    active,
    getUser,
    allowCountries,
    countryListNew,
    appListNew,
  } = useSelector((state) => state.dashboard);
  const [nextload, setNextload] = useState(false);
  const [rolenotfound, setRolenotfound] = useState(false);
  function emptyCount() {
    setHcpvalidatedcount('0');
    // setActiveCircleCount(0);
    // setInactiveCircleCount(0);
    // setActiveregisteredcount(0);
    setLoggedincount(0);
    // setNewregisteredcount(0)
    setCountrycount(0);
    setApplicationcount(0);
    setHcppendingcount(0);
    setUserTableCount(0);
  }
  const [appValue, setAppValue] = useState({
    country: '',
    app: '',
    hcpValidation: '',
    hcpPending: '',
    active: '',
    logged: '',
    newReg: '',
  });
  const content = [
    { title: 'Application Details', match: userTableCount },
    {
      title: 'Applications Onboarded',
      match: applicationcount,
      val: appValue.app,
    },
    {
      title: 'Countries Onboarded',
      match: countrycount,
      val: appValue.country,
    },
    {
      title: 'HCP Validated',
      match: hcpvalidatedcount,
      val: appValue.hcpValidation,
    },
    { title: 'HCP Pending', match: hcppendingcount, val: appValue.hcpPending },
    // {
    //   title: "Active Registered",
    //   match: activeregisteredcount,
    //   val: appValue.active,
    // },
    { title: 'Loggedin Users', match: loggedincount, val: appValue.logged },
    // {
    //   title: "New Registration",
    //   match: newregisteredcount,
    //   val: appValue.newReg,
    // },
  ];
  axios.defaults.withCredentials = true;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [isAppLoader, setAppLoader] = useState(false);

  async function getDetails(csrf, input = {}) {
    // setLoading(true);
    setVijay(true);
    setGoflag(true);
    const payload = { ...input };

    if (countryval) {
      payload.country = countryval;
    }
    if (startdate) {
      payload.startDate = startdate.toISOString();
    }
    if (enddate) {
      payload.endDate = enddate.toISOString();
    }
    if (dashapp) {
      payload.appName = dashapp;
    }
    // for (const i in content){
    if (active == 'Countries Onboarded') {
      countriesOnboarded(payload, setCountrycount, setActiveCountryData, csrf);
    } else if (active == 'Applications Onboarded') {
      applicationsOnboarded(
        payload,
        setApplicationcount,
        setActiveApplicationData,
        csrf
      );
    } else if (active == 'HCP Validated') {
      setHcpvalidated('');
      setGoValidated(true);
      // hcpValidated(payload,setHcpvalidatedcount,setHcpvalidated,setLoading,csrf)
      // hcpPending(payload,setHcppendingcount,setAlldata,setHcppending,setLoading,csrf)
    } else if (active == 'HCP Pending') {
      // hcpPending(payload,setHcppendingcount,setAlldata,setHcppending,setLoading,csrf)
      // hcpValidated(payload,setHcpvalidatedcount,setHcpvalidated,setLoading,csrf)
      setGoPending(true);
      setHcppending('');
    } else if (active == 'Loggedin Users') {
      login(
        payload,
        setLoggedincount,
        setActiveLoggedInData,
        csrf,
        setAppValue,
        appValue
      );
    }
    // else if (active == "Active Registered") {
    //   activeRegistered(
    //     payload,
    //     setActiveregisteredcount,
    //     setActiveRegisteredData,
    //     csrf
    //   );
    // } else if (active == "New Registration") {
    //   newRegistered(
    //     payload,
    //     // setNewregisteredcount,
    //     // setActiveNewregisteredData,
    //     csrf
    //   );
    // }
    // }
    // hcpPending(payload,setHcppendingcount,setAlldata,setHcppending,setLoading,csrf)

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
          ...payload,
          countAllFromStore: true,
        },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
            { email: user },
            {
              headers: { ...constant.headers, 'csrf-token': csrf },
            }
          )
          .then((response) => {
            if (countryval) {
              const filteredData = response.data.data.data.filter(
                (data) => data.country === countryval
              );
              if (dashapp) {
                const AppData = filteredData.filter(
                  (data) => data.application_name === dashapp
                );
                setUserTableCount(AppData.length);
                setData(AppData);
              } else {
                setUserTableCount(filteredData.length);
                setData(filteredData);
              }
            } else {
              setUserTableCount(response.data.data.data.length);
            }
          })
          .catch((error) => {
            console.log(sanitizeUserInput(JSON.stringify(error)), 'errorItem');
          });

        setAppValue({
          ...appValue,
          country: response.data[0].countries_onboarded,
          app: response.data[0].applications_onboarded,
          hcpValidation: response.data[0].hcp_validated,
          hcpPending: response.data[0].hcp_pending,
          // active: response.data[0].active_registered,
          // newReg: response.data[0].new_registered,
        });
        setHcppendingcount(
          response.data[0].hcp_pending
            ? response.data[0].hcp_pending.toString()
            : 0
        );
        // setNewregisteredcount(
        //   response.data[0].new_registered > 9999
        //     ? response.data[0].new_registered.toString().slice(0, -3) + "k"
        //     : response.data[0].new_registered.toString()
        // );
        setCountrycount(
          response.data[0].countries_onboarded
            ? response.data[0].countries_onboarded
            : 0
        );
        setApplicationcount(
          response.data[0].applications_onboarded
            ? response.data[0].applications_onboarded
            : 0
        );
        // setActiveregisteredcount(
        //   response.data[0].active_registered > 9999
        //     ? response.data[0].active_registered.toString().slice(0, -3) + "k"
        //     : response.data[0].active_registered.toString()
        // );
        // setActiveregisteredcount2(response.data[0].active_registered);
        setHcpvalidatedcount(
          response.data[0].hcp_validated
            ? response.data[0].hcp_validated.toString()
            : 0
        );
        // setActiveCircleCount(
        //   response.data[0].active_count > 9999
        //     ? response.data[0].active_count.toString().slice(0, -3) + "k"
        //     : response.data[0].active_count.toString()
        // );
        // setInactiveCircleCount(
        //   response.data[0].inactive_count > 9999
        //     ? response.data[0].inactive_count.toString().slice(0, -3) + "k"
        //     : response.data[0].inactive_count.toString()
        // );
        setLoading(false);
        setCardspin(true);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)), 'errorItem');
        setLoading(false);
      });
    login(
      payload,
      setLoggedincount,
      setActiveLoggedInData,
      csrf,
      setAppValue,
      appValue
    );
  }
  // React.useEffect(() => {
  //     console.log("running");
  //     const getToken = async () => {

  //       let resp = await httpHelper.getIns(
  //        `${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`
  //       ).call();
  //       console.log(resp.data.csrfToken,"response and try");
  //     };
  //     getToken();
  //   }, []);

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`, {
        headers: { ...constant.headers },
      })

      .then((res) => {
        dispatch(setToken(res.data.data.csrfToken));
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
            { email: user },
            {
              headers: {
                ...constant.headers,
                'csrf-token': res.data.data.csrfToken,
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            // setTimeout(() => {
            if (Object.keys(response.data).length == 0) {
              window.location.reload(false);
              // navigate('/rolenotfound')
            }
            //   }, 3000);

            if (response.data && response.data.data) {
              if (response?.data?.data?.role) {
                dispatch(setAllowedApplication(response.data.data));
                dispatch(newCountryList(response?.data?.data));
                dispatch(setGetUser(response.data.data));
                if (response.data.data && response.data.data) {
                  dispatch(
                    setInitialCountryValues(response.data.data?.options)
                  );
                  dispatch(
                    setAllowedCountries(
                      response.data.data.options.dashboardDefault.application
                    )
                  );
                  if (
                    response.data.data.role.toLowerCase() !== 'super admin' ||
                    role.toLowerCase() !== 'super admin'
                  ) {
                    getDetails(csrf, {
                      appName:
                        response.data.data.options.dashboardDefault.application,
                      country:
                        response.data.data.options.dashboardDefault.country,
                    });
                    setDashApp(
                      response.data.data.options.dashboardDefault.application
                    );
                    setCountry(
                      response.data.data.options.dashboardDefault.country
                    );
                    // dispatch(setActive("Please Select"));
                    dispatch(setActive('HCP Pending'));
                  }
                  if (
                    response.data.data.role.toLowerCase() === 'super admin' ||
                    role.toLowerCase() === 'super admin'
                  ) {
                    dispatch(setActive('HCP Pending'));
                    getDetails(csrf);
                  }
                }
              } else {
                navigate('/rolenotfound');
              }
            }
            dispatch(updateInitialLoader(false));
          })
          .catch((er) => {
            console.log('errorr ', er);
            setRolenotfound(true);
            dispatch(updateInitialLoader(false));
            dispatch(setAllowedApplication(''));
          });
      })

      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)), 'errorItem');
      });
  }, []);

  useEffect(() => {
    setAppLoader((pre) => !pre);
    if (countryval) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`,
          {
            headers: { ...constant.headers },
          }
        )
        .then((res) => {
          dispatch(setToken(res.data.data.csrfToken));
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/api/country-app-list`,
              { country: countryval },
              {
                headers: {
                  ...constant.headers,
                  'csrf-token': res.data.data.csrfToken,
                },
                withCredentials: true,
              }
            )
            .then((response) => {
              if (
                typeof response.data.data === 'object' &&
                Object.keys(response.data.data).length > 0
              ) {
                dispatch(newAppList(response.data.data.apps));
              } else {
                dispatch(newAppList([]));
              }
              setAppLoader((pre) => !pre);
            })
            .catch((er) => {
              console.log('errorr ', er);
              dispatch(newAppList([]));
              setAppLoader((pre) => !pre);
            });
        })
        .catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)), 'errorItem');
          dispatch(newAppList([]));
          setAppLoader((pre) => !pre);
        });
    } else {
      dispatch(newAppList([]));
      setAppLoader((pre) => !pre);
    }
  }, [countryval]);

  // React.useEffect(()=>{
  //     dispatch(setActive("HCP Pending"));
  //     if(role=="Super Admin"){
  //         dispatch(setActive("HCP Pending"));
  //         getDetails(csrf);
  //     }
  // },[role])

  // function removeDuplicates(arr) {
  //   return [...new Set(arr)];
  // }
  // const handleChange = (event) => {
  //   let value = event.target.value;
  //   let filterdata = [];
  //   if (value) {
  //     filterdata = alldata.filter(
  //       (item) => item.MDM_ID.includes(value) && item.MDM_ID !== null
  //     );
  //     setData(filterdata);
  //   } else {
  //     setData(alldata);
  //   }
  // };

  // const HcpValidatedColumns = [
  //   {
  //     name: "Party id/Prospect id",
  //     selector: (row) => (row.MDM_ID != null ? row.MDM_ID : row.prospect_id),
  //   },
  //   {
  //     name: "Status",
  //     selector: (row) => row.MDM_Status,
  //   },
  //   {
  //     name: "First name",
  //     selector: (row) => row.first_name,
  //     sortable: true,
  //   },
  //   {
  //     name: "Last name",
  //     selector: (row) => row.last_name,
  //   },
  //   {
  //     name: "Email",
  //     selector: (row) => row.email,
  //   },
  //   // {
  //   //     name: "Postal Code",
  //   //     selector: (row) => row.postal_code,
  //   // },
  //   {
  //     name: "Specialty",
  //     selector: (row) => row.Specialty,
  //   },
  //   // {
  //   //     name: "Phone",
  //   //     selector: (row) => row.mobile_number,
  //   // },
  //   // {
  //   //     name: "Institution name",
  //   //     selector: (row) => row.Institution,
  //   // },
  //   // {
  //   //     name: "License",
  //   //     selector: (row) => row.License,
  //   // },
  //   {
  //     name: "HCP Validation Submitted",
  //     selector: (row) => row.hcp_validation_submitted_date,
  //     sortable: true,
  //   },
  //   {
  //     name: "HCP Validation Completion",
  //     selector: (row) => row.hcp_validation_verified_date,
  //   },
  // ];
  return initialLoader || role === '' ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img src={Loadspinner} />
    </div>
  ) : (
    <div>
      {rolenotfound && <Rolenotfound />}

      {!rolenotfound && (
        <main
          style={{ margin: 0, height: '100vh' }}
          className={show ? 'space-toggle' : null}
        >
          <aside
            style={{ background: '#045EB7', zIndex: '105' }}
            className={`sidebar ${show ? 'show' : null}`}
          >
            <nav className="nav">
              <div>
                <div
                  className="header-toggle"
                  onClick={() => dispatch(setShow())}
                >
                  <span className="fas fa-bars sidebarfirst" />
                </div>
                <Sidebar show={show} />
              </div>
            </nav>
          </aside>
          <div className="maintop">
            <header
              style={{
                background: 'white',
                zIndex: '-1',
                padding: 0,
                height: '7vh',
              }}
              className={`header ${show ? 'space-toggle' : null}`}
            >
              <span>
                <img
                  style={{ paddingLeft: '1rem', height: '40px' }}
                  src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg"
                  alt=""
                />
              </span>

              <div>
                <span
                  aria-describedby={id}
                  role="button"
                  className="material-symbols-outlined headercolor fs-1 me-3"
                  onClick={handleClick}
                >
                  account_circle
                </span>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <UserPopover />
                </Popover>
              </div>
            </header>
            <div className="ms-4 select_go">
              <div
                className="d-flex mt-2 justify-content-end"
                style={{ zIndex: '1000' }}
              >
                <div className="d-flex align-items-center flex-wrap">
                  <div className="d-flex me-2">
                    <div className="p-0 me-1">
                      <label
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          paddingTop: '4px',
                        }}
                      >
                        Country
                      </label>
                    </div>
                    <div className="p-0">
                      <select
                        name="cars"
                        id="cars"
                        value={countryval}
                        onChange={(e) => {
                          dispatch(setActive('Please Select'));
                          setCountry(e.target.value);
                          setGoflag(false);
                          setCardspin(false);
                          emptyCount();
                          setDashApp('');
                        }}
                        style={{
                          border: '1px solid #ced4da',
                          width: '150px',
                          borderRadius: '5px',
                          height: '2rem',
                        }}
                      >
                        <option value="">Select Country</option>
                        {countryListNew?.map((x, index) => (
                          <option key={index} value={x.value}>
                            {x.label}
                          </option>
                        ))}
                        {/* <option value="US">US</option>
                                        <option value="CA">Canada</option>
                                        <option value="ES">Spain</option>
                                        <option value="FR">France</option>
                                        <option value="AU">Australia</option>
                                        <option value="DE">Germany</option> */}
                      </select>
                    </div>
                  </div>
                  {/* {role === "App Owner" ? */}
                  <div className="d-flex">
                    <div className="p-0 me-1">
                      <label
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          paddingTop: '4px',
                        }}
                      >
                        Application
                      </label>
                    </div>
                    <div className="p-0 me-2">
                      <select
                        name="cars"
                        id="cars"
                        onChange={(e) => {
                          if (e.target.value === '') {
                            dispatch(setSuperAdminAllowedCountries());
                          } else {
                            dispatch(setAllowedCountries(e.target.value));
                          }
                          dispatch(setActive('Please Select'));
                          setDashApp(e.target.value);
                          setGoflag(false);
                          setCardspin(false);
                          emptyCount();
                          // setCountry("");
                        }}
                        style={{
                          border: '1px solid #ced4da',
                          width: '150px',
                          borderRadius: '5px',
                          height: '2rem',
                        }}
                        value={dashapp}
                        data-toggle="tooltip"
                        title={
                          dashapp === ''
                            ? 'Select Application'
                            : appListNew.filter(
                                (x) => x.application_name === dashapp
                              )[0]?.aliasName
                        }
                      >
                        {!isAppLoader && (
                          <option value="">Select Application</option>
                        )}
                        {isAppLoader && <option value="">Loading...</option>}
                        {appListNew?.map((application, index) => {
                          return (
                            <option
                              key={index}
                              value={application.application_name}
                            >
                              {application.aliasName
                                ? application.aliasName
                                : application.application_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* :
                                ""
                                } */}
                </div>
                <div className="d-flex align-items-center">
                  <div className="mx-2">
                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        paddingTop: '4px',
                      }}
                    >
                      Date Range
                    </label>
                  </div>
                  <div className="d-flex flex-wrap me-2">
                    <div
                      className="me-2 form-control ps-0"
                      style={{
                        height: '2rem',
                        paddingTop: '2px',
                        width: 'max-content',
                      }}
                    >
                      <DateTimePicker
                        onChange={(e) => {
                          dispatch(setActive('Please Select'));
                          setGoflag(false);
                          onChange(e);
                          setCardspin(false);
                          emptyCount();
                        }}
                        value={startdate}
                      />
                    </div>
                    <div
                      className="form-control ps-0"
                      style={{
                        height: '2rem',
                        paddingTop: '2px',
                        width: 'max-content',
                      }}
                    >
                      <DateTimePicker
                        onChange={(e) => {
                          dispatch(setActive('Please Select'));
                          setGoflag(false);
                          onChange1(e);
                          setCardspin(false);
                          emptyCount();
                        }}
                        value={enddate}
                      />
                    </div>

                    {/* <DateRange
                                onChange={item => setState([item.selection])}
                                showDateDisplay={true}
                                showPreview={false}
                                ranges={state}
                                /> */}
                    {/* <select name="cars" id="cars" style={{
                                        border: "1px solid #ced4da",
                                        width: "100%",
                                        borderRadius: "5px",
                                        height:"2rem"
                                        }}
                                    >
                                    <option value="">Select Daterange</option>
                                    <option value="saab">Last 10 days</option>
                                    <option value="opel">Last 1 month</option>
                                    <option value="audi">Last 3 months</option>
                                </select> */}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    {role == 'App Owner' ||
                    role == 'Admin' ||
                    role?.toLowerCase() === 'dashboard only' ? (
                      isLoading ? (
                        <div>
                          <button
                            className="btn btn-primary d-flex align-items-center"
                            style={{ height: '2rem', width: '3rem' }}
                          >
                            {' '}
                            <span
                              className="spinner-border text-white spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      ) : (
                        <button
                          className="btn btn-primary d-flex align-items-center"
                          style={{ height: '2rem', width: '3rem' }}
                          disabled={!countryval}
                          onClick={(e) => {
                            setLoading(true);
                            if (active === 'Please Select') {
                              dispatch(setActive('HCP Pending'));
                              getDetails(csrf);
                            } else {
                              getDetails(csrf);
                            }
                          }}
                        >
                          Go
                        </button>
                      )
                    ) : isLoading ? (
                      <div>
                        <button
                          className="btn btn-primary d-flex align-items-center"
                          style={{ height: '2rem', width: '3rem' }}
                        >
                          {' '}
                          <span
                            className="spinner-border text-white spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    ) : (
                      <button
                        className="btn btn-primary d-flex align-items-center"
                        style={{ height: '2rem', width: '3rem' }}
                        onClick={(e) => {
                          // setCardspin(true)
                          setLoading(true);
                          if (active === 'Please Select') {
                            dispatch(setActive('HCP Pending'));
                            getDetails(csrf);
                          } else {
                            getDetails(csrf);
                          }
                        }}
                      >
                        Go
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card_top p-0">
              {content.map((data, index) => {
                return (
                  <div className="px-2 pt-2" style={{ width: '100%' }}>
                    <div
                      className={
                        active === data.title
                          ? 'position-relative active_dashboard_card rounded overflow-hidden'
                          : 'position-relative dashboard_card rounded overflow-hidden'
                      }
                      type="button"
                      onClick={() => {
                        if (
                          ((role == 'App Owner' ||
                            role == 'Admin' ||
                            role?.toLowerCase() === 'dashboard only') &&
                            (countryval == '' || !vijay)) ||
                          ((role == 'App Owner' ||
                            role == 'Admin' ||
                            role?.toLowerCase() === 'dashboard only') &&
                            (countryval == 'Select Country' || !vijay))
                        ) {
                        } else {
                          dispatch(setActive(data.title));
                        }
                      }}
                    >
                      <div className="position-absolute top-0 start-0 w-100 h-100 dashboard_main_card rounded overflow-hidden">
                        <div className="d-flex justify-content-start align-items-center mt-2">
                          <div className="text-white fs-3 fw-bold mt-2 ms-3">
                            {data.match}
                          </div>
                        </div>
                        <div className="w-100 d-flex  flex-wrap text-white mx-2 mt-1">
                          <div
                            className=" text-nowrap text-truncate"
                            data-toggle="tooltip"
                            title={data.title}
                            style={{ width: 'max-content' }}
                          >
                            {' '}
                            {data.title}
                          </div>
                          <div>
                            <sup
                              className="material-symbols-outlined text-info text-white"
                              role="button"
                              data-toggle="tooltip"
                              title={info[data.title]}
                              style={{ fontSize: '13px' }}
                            >
                              info
                            </sup>
                          </div>
                        </div>
                      </div>
                      <div className="position-absolute top-0 end-0 dashboard_circle_top overflow-hidden" />
                      <div className="position-absolute bottom-0 end-0 dashboard_circle_bottom overflow-hidden" />
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className=""
              style={
                {
                  // marginTop:"1%",
                }
              }
            >
              {active === 'Countries Onboarded' && (
                <div className="h-100">
                  <Country
                    setCountrycount={setCountrycount}
                    activeCountryData={activeCountryData}
                    setActiveCountryData={setActiveCountryData}
                    apps={allowedApp}
                    startdate={startdate}
                    enddate={enddate}
                    country={countryval}
                    dashapp={dashapp}
                    setDashApp={setDashApp}
                    goflag={goflag}
                  />
                </div>
              )}
              {active === 'Applications Onboarded' && (
                <Application
                  setApplicationcount={setApplicationcount}
                  activeApplicationData={activeApplicationData}
                  setActiveApplicationData={setActiveApplicationData}
                  apps={allowedApp}
                  startdate={startdate}
                  enddate={enddate}
                  country={countryval}
                  dashapp={dashapp}
                  setDashApp={setDashApp}
                  goflag={goflag}
                />
              )}
              {active === 'Application Details' && (
                <UserTable
                  setUserTableCount={setUserTableCount}
                  activeApplicationData={activeApplicationData}
                  setActiveApplicationData={setActiveApplicationData}
                  apps={allowedApp}
                  startdate={startdate}
                  enddate={enddate}
                  country={countryval}
                  dashapp={dashapp}
                  setDashApp={setDashApp}
                  goflag={goflag}
                />
              )}
              {active === 'HCP Validated' && (
                <div
                  // style={{height:"1vh"}}
                  onClick={() => {
                    hcpValidated(
                      {
                        country: countryval,
                        startDate: startdate.toISOString(),
                        endDate: enddate.toISOString(),
                        appName: dashapp,
                      },
                      setHcpvalidatedcount,
                      setHcpvalidated,
                      setLoading,
                      csrf
                    );
                  }}
                >
                  {/* <input 
                                className="form-control border-end-0 border rounded-pill" 
                                placeholder=" Search User" 
                                style={{ height: "47px",
                                        width: "33%",
                                        margin:"0 4% 1% 65%"
                                    }}  
                                onChange={handleChange} 
                                type="search" 
                                id="example-search-input"
                                /> */}
                  {role === 'App Owner' ? (
                    ''
                  ) : (
                    <div className="d-flex justify-content-end">
                      {nextload && <Loader />}
                      <div className="col-3">
                        {/* <select name="cars" id="cars"  
                                        onChange={(e) => {setDashApp(e.target.value)}} 
                                        style={{
                                                border: "1px solid #ced4da",
                                                width: "100%",
                                                borderRadius: "5px",
                                                height:"2rem"
                                                }}
                                            >
                                            <option value="">Select Application</option>
                                            {adminApplications?.map((application)=>{
                                                return <option value={application}>{application}</option>
                                            })}
                                            
                                        </select> */}
                      </div>
                    </div>
                  )}
                  <div className="pb-2" style={{ height: '60vh' }}>
                    {/* <MyComponent data={hcpvalidated} paginationPerPage={10} columns={HcpValidatedColumns} setMaxLength={true} totalRows={hcpvalidatedcount}/> */}
                    <DynamicDataTableHcpValidated
                      dashapp={dashapp}
                      startdate={startdate}
                      enddate={enddate}
                      country={countryval}
                      goValidated={goValidated}
                      csrf={csrf}
                      setGoValidated={setGoValidated}
                      setNextload={setNextload}
                      goflag={goflag}
                    />
                  </div>
                </div>
              )}
              {active === 'HCP Pending' && (
                <div
                // style={{height:"1vh"}}
                >
                  {role === 'App Owner' ? (
                    ''
                  ) : (
                    <div className="d-flex justify-content-end">
                      {nextload && <Loader />}
                      <div className="col-3">
                        {/* <select name="cars" id="cars"  
                                    onChange={(e) => {setDashApp(e.target.value)}} 
                                    style={{
                                            border: "1px solid #ced4da",
                                            width: "100%",
                                            borderRadius: "5px",
                                            height:"2rem"
                                            }}
                                        >
                                        <option value="">Select Application</option>
                                        {adminApplications?.map((application)=>{
                                            return <option value={application}>{application}</option>
                                        })}
                                        
                                    </select> */}
                      </div>
                    </div>
                  )}
                  <div className="pb-2" style={{ height: '60vh' }}>
                    {/* <MyComponent data={hcppending} paginationPerPage={10} columns={HcpValidatedColumns}setMaxLength={true} totalRows={hcppendingcount}/> */}
                    <DynamicDataTableHcpPending
                      dashapp={dashapp}
                      startdate={startdate}
                      enddate={enddate}
                      country={countryval}
                      vijay={vijay}
                      goPending={goPending}
                      setGoPending={setGoPending}
                      csrf={csrf}
                      setNextload={setNextload}
                      goflag={goflag}
                    />
                    {/* {
                                        maxLengthPending ? 
                                            <div 
                                            className="text-dark d-flex justify-content-end mb-1"
                                            >** By default, we only load up to 10000 user details</div> 
                                        : ""
                                    } */}
                  </div>
                </div>
              )}
              {/* {active === "Active Registered" && (
                <Active
                  activeregisteredcount={activeregisteredcount}
                  activeregisteredcount2={activeregisteredcount2}
                  activeRegisteredData={activeRegisteredData}
                  setActiveregisteredcount={setActiveregisteredcount}
                  setActiveRegisteredData={setActiveRegisteredData}
                  apps={allowedApp}
                  dashapp={dashapp}
                  startdate={startdate}
                  enddate={enddate}
                  country={countryval}
                  setDashApp={setDashApp}
                  goflag={goflag}
                  vijay={vijay}
                  goPending={goPending}
                  setGoPending={setGoPending}
                  csrf={csrf}
                  setNextload={setNextload}
                />
              )} */}
              {active === 'Loggedin Users' && (
                <Login
                  apps={allowedApp}
                  country={countryval}
                  startdate={startdate}
                  enddate={enddate}
                  setLoggedincount={setLoggedincount}
                  loggedincount={loggedincount}
                  setActiveLoggedInData={setActiveLoggedInData}
                  dashapp={dashapp}
                  activeLoggedInData={activeLoggedInData}
                  goflag={goflag}
                />
              )}
              {/* {active === "New Registration" && (
                <Newregistration
                  setNewregisteredcount={setNewregisteredcount}
                  activeNewregisteredData={activeNewregisteredData}
                  setActiveNewregisteredData={setActiveNewregisteredData}
                  apps={allowedApp}
                  dashapp={dashapp}
                  startdate={startdate}
                  enddate={enddate}
                  country={countryval}
                  setDashApp={setDashApp}
                  activeCircleCount={activeCircleCount}
                  inactiveCircleCount={inactiveCircleCount}
                  goflag={goflag}
                  vijay={vijay}
                  goPending={goPending}
                  setGoPending={setGoPending}
                  csrf={csrf}
                  setNextload={setNextload}
                />
              )} */}
              {active === 'Please Select' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '56vh',
                  }}
                >
                  {role === 'Super Admin' ? (
                    <div>Click go to see the data</div>
                  ) : (
                    <div>Please select Country and Date to see the data</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </main>
      )}
    </div>
  );
}

export default Dashboard;
